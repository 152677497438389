
import { Component, Vue } from "vue-property-decorator";
import Purenavbar from "@/components/Utility/Purenavbar.vue";
import MobileQuestion from "@/components/MobileQA/MobileQuestion.vue";
import MobileAns from "@/components/MobileQA/MobileAns.vue";
import MobileFinish from "@/components/MobileQA/MobileFinish.vue";

@Component({
  components: {
    Purenavbar,
    MobileQuestion,
    MobileAns,
    MobileFinish,
  },
})
export default class MobileQA extends Vue {
  //data
  chooseQuestion = 0;
  questionVisible = true;
  answerVisible = false;
  finishVisible = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  MobileQAList: { Question: string; Ans: string[] }[] = [
    {
      Question: "我有兩種不同的工項需求，是不是要下兩次單?",
      Ans: [
        "不需要，您可以選擇主要工項，在填寫訂單頁面「說明欄位」，描述另外需要的工項服務即可，師傅們將會依據您的需求來決定是否承接案件。",
      ],
    },
    {
      Question: "我可以先了解產品或服務的價格為何嗎?",
      Ans: [
        "您可以依照需求選擇服務工項，每個服務工項都有歷史價格區間範圍可以參考喔，讓您心裡有一個底。我們也會盡可能要求師傅報價落此價格區間範圍，但實際上仍會依照現場環境、品牌、型號等等有所差異喔。",
      ],
    },
    {
      Question:
        "為什麼下單時無法查看該區域所有師傅的資歷，讓我可以挑選喜歡的師傅?",
      Ans: [
        "1.為了讓師傅配合您的時間。",

        "師虎來了是最先進的即時修繕媒合共享平台，不同於傳統其它平台做法，我們平台採取即時媒合的方式，如同您叫計程車一樣，讓您在您需要的時間，就可以有在您附近且適合的師傅為您服務，而不是讓您去配合師傅的時間。",

        "2.嚴格篩選並持續考核師傅制度，盡可能維持五星師傅為您服務。",

        "師虎的師傅除了需參加師虎舉辦的說明會之外，也必須通過我們一對一的面試，而我們錄取率只有36%，非常嚴謹篩選，即使加入成為師虎的夥伴後，我們的評鑑考核制度也不會停止。",

        "所以您不需費心自己挑選師傅，師虎的施工品質、師傅素質普遍都會比其他管道來的更好，並且完工後的售後保固服務皆由師虎平台負責，不會讓您曝露在挑選錯誤師傅的風險中。",
      ],
    },
    {
      Question: "請問你們的服務範圍為何？",
      Ans: [
        "目前西半部都有師傅可以承接案件，您可以參考我們官網上師傅分布的狀況，但可能會因各種類的師傅數量或是時間等等因素，發生某些地區雖然有師傅，但卻媒合不到師傅的情形，因此，師虎來了團隊持續會積極招募優質的師傅，讓每個地區的客戶都可以在想要的時間得到所需要的修繕服務。",
      ],
    },
    {
      Question: "沒有媒合成功，是不是要重新下單？",
      Ans: [
        "沒有媒合成功可能是因為您附近的可以承接的師傅們正在忙碌工作，沒辦法即時注意到您的訂單，又或是您預約的時間、工項剛好沒有師傅可以配合提供。您可以再次重新下單媒合看看，或是重新下單媒合後，點選「由專人協助媒合」按鍵，將會有專人為您協尋師傅處裡您的訂單喔。",
      ],
    },
    {
      Question: "可以先讓師傅到我家估價嗎",
      Ans: [
        "因為我們是共享媒合平台，所以我們提供的服務是讓優質的師傅在他閒暇的時間可以承接案件。因此，必須先請您下單，媒合成功後才能確定是由哪位師傅接單，師傅也會在您指定的時間前往勘查喔!",
        "媒合成功後，您也需先支付派遣費，提供師傅前往勘查的車馬費用，師傅才會出發! 不必擔心，派遣費用會在後續施工款項全額抵扣，但若您因各種因素決定不進行維修了，派遣費用將無法退費的喔，還請您特別注意。",
      ],
    },
    {
      Question: "請問派遣費用的用途，還有是不是不會退費?",
      Ans: [
        "平台為了建立公平的交易制度，我們保障您的預約時間及師傅派工權益，師傅即使沒有完修此筆訂單，師傅仍應獲得基本的車馬費，包含他的時間、車資、停車費等等。師虎來了會確實要求師傅提供必要的解決方案或是修繕的建議(在某些情況下，並非一定可以順利處理，譬如隔壁家漏水過來我們這邊，需要能夠協調隔壁住戶做修繕等等)，因此請您不用擔心付出派遣費用沒有得到任何協助或處理，謝謝。",
      ],
    },
    {
      Question: "請問派遣費會開發票嗎?",
      Ans: [
        "會，您在平台上所有支付的款項都會開立電子發票，並約2-3天寄送至您註冊時所填寫的郵件信箱，若沒收到您可試著在郵件中的垃圾桶看看，且包含後續的工程修繕款項都會開立發票!",
        "若您是公司行號需要報帳，可以在填寫訂單頁面下方輸入您的抬頭與統一編號。",
      ],
    },
    {
      Question: "請問如果我不知道怎麼選擇工項，我要如何下單?",
      Ans: [
        "如果您不知道如何選擇工項，您可以透過關鍵字搜尋看看，搜尋結果將會顯示所有相關的工項，您可以選擇最接近的工項，並且在訂單填寫頁面寫下您的狀況或是同時上傳照片，師傅會根據您寫的狀況來決定要不要接案。若您真的選不到任何您覺得相近的工項，您可以下單修繕顧問，並且於下單頁面寫下您的狀況描述，讓師虎的技術客服管理師來協助您做問題處理，謝謝您。",
      ],
    },
    {
      Question: "請問我要廚房跟浴室還有一些小局部翻新，我可以怎樣下單?",
      Ans: ["您可以下單兩個區域以上翻新，即可媒合師傅到您現場勘查報價喔。"],
    },
    {
      Question: "請問免設計圖客製施工是如何進行?",
      Ans: [
        "免設計圖客製施工主要是提供給一些對自己房屋改造有想法的客戶，又或是在預算比較侷限的客戶。這樣的案件會由專業的統包師傅團隊來做接案，他們可以依據跟您的討論內容提供報價單，並於施工過程中做監工確保施工的品質。師傅也會針對您的裝修修繕提供最專業的建議，承接統包案件的師傅都承接過相當多的設計施工案，您可參考師虎部落格中的客戶案例，也須多都是由統包師傅完成的個案，他們對實際施工技巧與工法都是相當了解，如果您想把繪製設計圖檔的費用節省下，這是您最佳的選擇。",
      ],
    },
    {
      Question: "如果我叫修後覺得師傅不滿意，我可以怎麼做?",
      Ans: [
        "您可以在您「進行中訂單」右上方跟師虎技術客服管理師做陳述，讓師虎技術客服管理師協助您與師傅溝通，亦或是在必要的情況下為您更換師傅，為您做好把關。",
      ],
    },
    {
      Question: "我想成為師虎來了的師傅，我可以怎樣參加呢?",
      Ans: [
        "您可以在我們官網上www.fdtigermaster.com 選擇您可以的場次，報名參加說明會，通過考核後，就可以在師虎來了平台上接案喔。",
      ],
    },
  ];
  //computed
  get AnswerList() {
    return this.MobileQAList[this.chooseQuestion];
  }
  //methods
  getIndex(e: any): void {
    let index = e.target.getAttribute("index");
    this.chooseQuestion = index;
    this.questionVisible = false;
    this.answerVisible = true;
  }
  back(): void {
    this.questionVisible = true;
    this.answerVisible = false;
    this.finishVisible = false;
  }
  finish(): void {
    this.questionVisible = false;
    this.answerVisible = false;
    this.finishVisible = true;
  }
}
