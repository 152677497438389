
import { Component, Prop, Vue } from "vue-property-decorator";
import tigermaster from "fdtigermaster-client-sdk";

@Component
export default class MobileAns extends Vue {
  @Prop() public back!: any;
  @Prop() public finish!: any;
  @Prop() public AnswerList!: { Question: string; Ans: string[] };

  mounted() {
    this.scrollToTop();
  }

  updated() {
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  //methods
  feedbackSubmitClick(res: string): void {
    tigermaster.enquire.createFeedback({
      type: "mobile_qa_helpful",
      cause: this.AnswerList.Question,
      content: res,
    });
    this.finish();
  }
}
