
import { Prop, Component, Vue } from "vue-property-decorator";

@Component
export default class MobileQuestion extends Vue {
  @Prop() public getIndex!: any;
  @Prop() public MobileQAList: { 
    index: number;
    Question: string; 
    Ans: string[] 
  }[];

  mounted() {
    this.scrollToTop();
  }

  updated() {
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
